import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Wrapper from "../components/styles/Wrapper";
import Button from "../components/styles/Button";

const NavStyles = styled.nav`
  margin: 0 64px;
  @media (max-width: 800px) {
    margin: 0 20px;
  }

  display: flex;
  flex-wrap: no-wrap;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 1px solid rgb(0 0 0 / 10%);

  padding: 5px 0 0 0;
  align-items: center;
  justify-content: space-between;

  .active {
    border-bottom: solid 4px var(--green);
    font-weight: 700;
  }
`;

const NavUl = styled.ul`
  display: flex;
  flex: 2;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;

  @media (max-width: 600px) {
    order: 3;
  }
`;

const NavLi = styled.li`
  text-transform: uppercase;
  padding: 0 50px 0 0;

  a {
    color: var(--semi-gray);
  }

  a:hover {
    /* background-color: var(--green); */
    border-bottom: solid 4px var(--green);
    font-weight: 700;
  }
`;

const infoMessage = styled.div`
  height: 40px;
  background-color: blue;
`;

const Nav = () => {
  return (
    <header>
      <Wrapper>
        <NavStyles>
          <Link to="/">
            <StaticImage
              src="../assets/images/logo.png"
              alt="tiny tots logo"
              width={137}
            />
          </Link>
          <NavUl>
            <NavLi>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </NavLi>
            <NavLi>
              <Link to="/packages" activeClassName="active">
                Packages
              </Link>
            </NavLi>
            <NavLi>
              <Link to="/faq" activeClassName="active">
                FAQs
              </Link>
            </NavLi>
          </NavUl>
          <div className="buttonHolder">
            <Button target="_blank" rel="noopener" to="/">
              GET A QUOTE
            </Button>
          </div>
        </NavStyles>
      </Wrapper>
    </header>
  );
};

export default Nav;
