import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import Wrapper from "../components/styles/Wrapper";
import { StaticImage } from "gatsby-plugin-image";

const FooterStyles = styled.footer`
  margin-top: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #beeedd;
  height: 250px;
  text-align: center;
  font-weight: 700;
  align-items: center;
`;

const SocialMedia = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
`;

const Footer = () => {
  return (
    <FooterStyles>
      <h2>Connect with us on Social Media!</h2>
      <SocialMedia>
        <Link
          target="_blank"
          rel="noopener"
          to="https://www.facebook.com/tinytotsfunzone"
        >
          <StaticImage
            src="../assets/images/facebook_icon.png"
            alt="facebook logo icon"
            width={32}
          />
        </Link>
        <Link
          target="_blank"
          rel="noopener"
          to="https://www.instagram.com/tinytotsfunzone"
        >
          <StaticImage
            src="../assets/images/instagram_icon.png"
            alt="instagram logo icon"
            width={32}
          />
        </Link>
        <div>
          <h3>Follow @tinytotsfunzone</h3>
        </div>
      </SocialMedia>
      <div>
        <Link to="/">
          <h3>© 2021 Tiny Tots Fun Zone</h3>
        </Link>
      </div>
    </FooterStyles>
  );
};

export default Footer;
