import { createGlobalStyle } from "styled-components";
import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/900.css";
import "@fontsource/source-sans-pro/700.css";
import "@fontsource/source-sans-pro/300.css";

const GlobalStyles = createGlobalStyle`
:root {
    --light: #ffffff;
    --dark: #000000;
    --dark-gray: #404040;
    --semi-gray: #5c5d60;
    --light-gray: #605F5F;
    --pink: hsl(327,79%,76%);
    --dark-pink: hsl(327,79%,54%);
    --dark-pink: hsl(340,100%,70%);
    --green: hsl(158,59%,64%);
    --yellow: hsl(45,88%,57%);
    --blue: hsl(191,63%,60%);
    --light-blue: hsl(187,25%,80%);
    --clear-blue: hsl(191,63%,95%);
    --maxWidth: 1044px;

  }

html {
  height: 100%;
  
}

body {
    font-family: "Source Sans Pro";
    font-size: 1rem;

    /* overflow-y: scroll;
    -webkit-overflow-scrolling: touch; */
}


main {
  flex: 1 0 auto;
  margin-bottom: 20px;
}

a {
    color: var(--dark);
    text-decoration: none;
}

h1 {
    font-size: 2.5rem;
    color: var(--dark-gray);
    font-weight: 900;
    letter-spacing: -0.04em;
    line-height: 92.68%;
    text-align: center;
  }

h2 {
    font-weight: 600;
    font-style: normal;    
    font-size: 2em;
    color: var(--dark-gray);
}


h3 {
    font-weight: 600;
    font-style: normal;    
    font-size: 1.2em;
    color: var(--dark-gray);
}

p {
    line-height: 1.5rem;
    font-weight: 300;
}

`;

export default GlobalStyles;
