import React from "react";
import styled from "styled-components";
import GlobalStyles from "../components/styles/GlobalStyles";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const LayoutStyles = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/qng4edg.css" />
      </Helmet>
      <LayoutStyles>
        <Nav />
        <main>{children}</main>
        <Footer />
      </LayoutStyles>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
