import { Link } from "gatsby";
import styled from "styled-components";

const Button = styled(Link)`
  padding: 5px 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: white;
  background-color: var(--pink);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  :hover {
    background-color: var(--green);
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translate(0%, -10%);
    transition: 0.3s ease-out;
  }
`;

export default Button;
